<template>
   <section class="cont not-check-left">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-flex-box">
         <el-col :span="6" class="left-table-bg">
            <el-row class="left-table">
               <el-table
                     ref="sideTable"
                     v-loading="sideLoad"
                     :data="sideData"
                     border fit
                     highlight-current-row
                     @row-click="(row) => handleCurrentChange(row)"
                     row-key="id"
                     style="width: 100%;"
                     max-height="780"
                     :stripe="true"
                     size="medium">
                  <el-table-column prop="name" :label="$t('msg.org_list')"></el-table-column>
               </el-table>
            </el-row>
         </el-col>
         <el-col :span="18" class="right-table-bg">
            <el-row class="right-table">
               <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label v-text="$t('msg.tenant_store_a')">单租户/商户：</label>
                        <el-cascader
                              class="width-220"
                              v-model="publics.companyId"
                              @change="changeCompany"
                              :props="companies"
                              :show-all-levels="false">
                        </el-cascader>
                     </el-row>
                     <el-row class="search-item">
                        <label v-text="$t('msg.user_name_nickname_a')">用户昵称：</label>
                        <el-input class="width-220"  v-model="param.nickName" :placeholder="$t('msg.user_name_nickname')"  clearable></el-input>
                     </el-row>
                     <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                     <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
                  </el-row>
               </el-row>
               <el-row class="table-box">
                  <div class="table-head-btn">
                     <el-row></el-row>
                     <el-row class="right-btn">
                        <el-button  type="primary"  @click="partakeRole" plain><span v-text="$t('msg.participation_role')">参与角色</span></el-button>
                        <el-button class="bg-gradient" type="primary"  icon="el-icon-plus"  @click="handleAdd"><span v-text="$t('msg.add_user')"></span></el-button>
                     </el-row>
                  </div>
                  <el-table
                        ref="mainTable"
                        v-loading="tableLoad"
                        :data="tableData"
                        border fit
                        highlight-current-row
                        @row-click="(row) => handleTableSelect(row)"
                        style="width: 100%"
                        :stripe="true">
                     <el-table-column prop="number" label="序号" width="60">
                        <template slot-scope="scope">
                           {{scope.$index + 1}}
                        </template>
                     </el-table-column>
                     <el-table-column :label="$t('msg.head_portrait')">
                        <template slot-scope="scope">
                           <img class="headPortrait" v-if="scope.row.headPortrait" style="width: 35px;height: 35px;border-radius: 50%" :src="scope.row.headPortrait" alt="">
                           <img class="headPortrait" v-else style="width: 35px;height: 35px;border-radius: 50%" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" alt="">
                        </template>
                     </el-table-column>
                     <el-table-column prop="userName" :label="$t('msg.user_name')" width="180"></el-table-column>
                     <el-table-column prop="userNum" label="工号" ></el-table-column>
                     <el-table-column :label="$t('msg.gender')">
                        <template slot-scope="scope">
                           <p>{{scope.row.gender | filterGender(that)}}</p>
                        </template>
                     </el-table-column>
                     <el-table-column :label="$t('msg.status')">
                        <template slot-scope="scope">
                           <p>{{scope.row.state | filterState}}</p>
                        </template>
                     </el-table-column>
                     <el-table-column prop="duty" label="职务"></el-table-column>
                     <el-table-column prop="jobTitle" label="职称"></el-table-column>
                     <el-table-column prop="orgName" :label="$t('msg.affiliated_org')" width="140"></el-table-column>
                     <el-table-column prop="companyName" :label="$t('msg.tenant_store')" min-width="190"></el-table-column>
                     <el-table-column prop="createTime" :label="$t('msg.create_time')" width="170"></el-table-column>
                     <el-table-column :label="$t('msg.operating')" fixed="right" width="200">
                        <template slot-scope="scope">
                           <el-button type="text" @click.stop="handleEdit(scope.row)">
                              <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                           </el-button>
                           <el-button type="text" v-show="scope.row.isOwner?false:true" style="color: #F56C6C;" @click.stop="handleDel(scope.row.id)">
                              <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                           </el-button>
                           <el-button type="text" style="color: #F56C6C;" @click.stop="resetPwd(scope.row.userName, scope.row.id)">
                              <el-link type="danger" v-text="$t('msg.reset_password')">重置密码</el-link>
                           </el-button>
                        </template>
                     </el-table-column>
                  </el-table>
                  <!-- 分页 -->
                  <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
               </el-row>
            </el-row>
         </el-col>
      </el-row>
      <!-- 修改密码 -->
      <el-dialog title="修改密码"  :visible.sync="isShowPwd"  width="550px">
         <el-form class="form-dialog-box">
            <el-form-item label="用户名：">
               <span>{{ userName}}</span>
            </el-form-item>
            <el-form-item label="新密码：">
               <el-input  v-model="password" :placeholder="$t('msg.inp_new_pwd')"></el-input>
            </el-form-item>
         </el-form>
         <div slot="footer" class="dialog-footer">
            <el-button @click="isShowPwd = false">取 消</el-button>
            <el-button type="primary" @click="saveNewPwd">确 定</el-button>
         </div>
      </el-dialog>
      <!-- 添加/编辑弹窗 -->
      <el-dialog :title="`${action === 'add' ? '新建' : '编辑'}用户`" :close-on-click-modal="false" :before-close="handleClose" :visible.sync="isShow" width="700px">
            <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
               <el-row class="form-row">
                  <el-form-item label="组织名称：" v-show="orgIsShow">
                     <p>{{ orgName }}</p>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="头像：">
                     <el-upload
                           class="avatar-uploader"
                           ref="upload"
                           :show-file-list="false"
                           action="#"
                           :auto-upload="false"
                           :on-change="handleUpload">
                        <img v-if="headPortrait" :src="headPortrait" class="avatar-mini">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                     </el-upload>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="用户名：" prop="userName">
                     <el-input  class="width-200" v-model.trim="ruleForm.userName" :disabled="action === 'edit'" :placeholder="$t('msg.user_name')" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="手机号码：" prop="mobile">
                     <el-input  class="width-200" v-model.trim="ruleForm.mobile" :placeholder="$t('msg.phone_number')" clearable></el-input>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="昵称：" prop="nickName">
                     <el-input  class="width-200" v-model.trim="ruleForm.nickName" :placeholder="$t('msg.nickname')" clearable maxlength="16"></el-input>
                  </el-form-item>
                  <el-form-item label="电子邮箱：" prop="email">
                     <el-input  class="width-200" v-model.trim="ruleForm.email" :placeholder="$t('msg.e_mail')" clearable></el-input>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="工号：">
                     <el-input  class="width-200" v-model.trim="ruleForm.userNum" placeholder="工号" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="是否管理员：" >
                     <el-radio-group v-model="ruleForm.isOwner">
                        <el-radio class="m-right-10" :label="false">否</el-radio>
                        <el-radio class="m-right-10" :label="true">是</el-radio>
                     </el-radio-group>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="性别：">
                     <el-radio-group v-model="ruleForm.gender">
                        <el-radio class="m-right-10" label="SECRET">秘密</el-radio>
                        <el-radio class="m-right-10" label="MAN">男</el-radio>
                        <el-radio class="m-right-10" label="WOMAN">女</el-radio>
                     </el-radio-group>
                  </el-form-item>
                  <el-form-item label="是否启用：">
                     <el-switch v-model="ruleForm.state" active-value="PASSED" inactive-value="DISABLE"></el-switch>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="职务：">
                     <el-input  class="width-200"  v-model="ruleForm.duty" placeholder="职务" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="职称：">
                     <el-input class="width-200"  v-model="ruleForm.jobTitle" placeholder="职称" clearable></el-input>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="备注信息：">
                     <el-input  class="width-300"  type="textarea" v-model="ruleForm.remark" :placeholder="$t('msg.remark')" clearable></el-input>
                  </el-form-item>
               </el-row>
             </el-form>
             <span slot="footer" class="dialog-footer">
               <el-button  @click="resetForm" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
             </span>
          </el-dialog>
      <!-- 参与角色 -->
      <el-form label-width="105px" class="user-execute">
               <el-dialog :title="$t('msg.select_role')" :visible.sync="roleIsShow" width="700px">
                   <div class="execute">
                       <div class="execute-room">
                           <div class="top-text"><span v-text="$t('msg.role_list')">角色列表</span></div>
                           <el-table
                                   :data="unRoleData"
                                   border
                                   el-table-column
                                   style="width: 100%;"
                                   @selection-change="handleSelectionChange"
                                   max-height="780"
                                   :stripe="true"
                                   >
                               <el-table-column type="selection"></el-table-column>
                               <el-table-column prop="roleName" :label="$t('msg.role_name')"></el-table-column>
                               <el-table-column prop="roleCode" :label="$t('msg.role_encoding')"></el-table-column>
                           </el-table>
                       </div>
                       <p @click="saveRelation"><i class="el-icon-d-arrow-right"></i></p>
                       <div class="execute-room">
                           <div class="top-text"><span v-text="$t('msg.selected_roles')">已选角色</span></div>
                           <el-table :data="roleData" border style="width: 100%;" max-height="780" :stripe="true" >
                               <el-table-column prop="roleName" :label="$t('msg.role_name')"></el-table-column>
                               <el-table-column prop="roleCode" :label="$t('msg.role_encoding')"></el-table-column>
                               <el-table-column :label="$t('msg.remove')" fixed="right">
                                   <template slot-scope="scope">
                                       <el-button @click="handleUntying(scope.row.id)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                           <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                                       </el-button>
                                   </template>
                               </el-table-column>
                           </el-table>
                       </div>
                   </div>
               </el-dialog>
           </el-form>
    </section>
</template>
<script>
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
import { mapState } from 'vuex'
import { beforeUpload, verificationRule, getDict } from '@/common/js/common'
export default {
   data(){
      let self = this
      let validMobile = (rule, value, callback) => {
         if (!value) {
            callback(new Error('请输入手机号'))
         }else if (verificationRule.mobile(value)) {
            callback(new Error('手机号格式错误'))
         }else {
            callback()
         }
      }
      let validEmail = (rule, value, callback) => {
         if (!value) {
            callback(new Error('请输入邮箱'))
         }else if (verificationRule.email(value)) {
            callback(new Error('邮箱格式错误'))
         }else {
            callback()
         }
      }
      return{
         sideData: [],        // 侧边栏列表
         tableData: [],       // 表格用户列表
         unRoleData: [],      // 未选角色列表
         roleData: [],        // 已选角色列表
         sideLoad: true,      // 侧边栏开启加载效果
         tableLoad: true,     // 表格开启加载效果
         boxIcon: true,       // 侧边栏折叠状态
         total: 0,            // 用户列表总条目数
         isShow: false,       // 是否显示添加/编辑弹窗
         isShowPwd: false,    // 是否显示修改密码弹窗
         roleIsShow: false,   // 是否显示参与角色弹窗
         action: 'add',       // 当前操作行为
         orgName: '',         // 组织名称
         orgIsShow: false,    // 组织名称是否显示
         companyName: '',     // 租户/商户列表
         password: '',        // 用户新密码
         headPortrait: '',    // 头像地址
         that: this,
         companies: {         // 公司列表
            lazy: true,
            checkStrictly: true,
            async lazyLoad(node, resolve){
               const { value,hasChildren } = node
               if (hasChildren === false) return resolve([])
               let data = await self.getCompanies(value)
               resolve(data)
            }
         },
         page: 1,             // 当前页
         limit: 15,           // 每页数
         publics: {
            companyId: '',    // 租户/商户ID
            orgId: '',        // 组织ID
         },
         param: {
            nickName: '',     // 用户名/昵称
         },
         fileForm: {
            file: null,
            module: 'system',
            menu: 'user',
            func: 'headportrait',
            category: 'headportrait',
            isThumb: 'true'
         },
         ruleForm: {
            userName: '',        // 用户名
            mobile: '',          // 手机号
            nickName: '',        // 昵称
            email: '',           // 邮件
            userNum: '',         // 工号
            isOwner: false,      // 是否管理员
            gender: 'SECRET',    // 性别
            state: 'PASSED',     // 状态
            duty: '',            // 职务
            jobTitle: '',        // 职称
            remark: '',          // 备注
         },
         rules: {
            userName: [
               { required: true, message: '请输入用户名', trigger: 'blur' }
            ],
            mobile: [
               { required: true, validator: validMobile, trigger: 'blur' }
            ],
            nickName: [
               { required: true, message: '请输入昵称', trigger: 'blur' }
            ],
            email: [
               { required: true, validator: validEmail, trigger: 'blur' }
            ]
         },
         userName: '',
         id: '',              // 编辑id
         /* 参与角色添加字段 */
         userId: '',          // 当前选中用户id 用于获取和修改角色列表
         roleIds: [],         // 保存未授予角色列表选项
      }
   },
   computed: {
      ...mapState(['dictData'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.initForm = { ...this.ruleForm }
      Object.setPrototypeOf(this.ruleForm, this.publics)
      getDict(['gender'])
      this.getUser()
      this.getOrg()
   },
   methods: {
      // 获取用户列表
      getUser(){
         const param = { ...Object.assign({}, this.param, this.publics) }
         const url = system.userList + `?limit=${this.limit}&page=${this.page}`
         this.$axios.post(url, param, 'json').then((res) => {
            if(res.success){
               this.tableLoad = false
               this.total = res.total
               let tableData = res.records
               tableData.forEach(item => {
                  item.headPortrait && (item.headPortrait = window.getResourcesHost() + item.headPortrait)
               })
               this.tableData = tableData
            }
         })
      },
      // 获取组织列表
      getOrg(){
         const param = { companyId: this.publics.companyId }
         const url = system.org
         this.$axios.post(url, param, 'json').then(res => {
            if(res.success){
               this.sideLoad = false
               this.sideData = res.children
            }
         })
      },
      // 获取租户/商户列表
      getCompanies(companyId){
         return new Promise((resolve, reject) => {
            const url = system.companies
            const { accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
            let param = {}
            if (accountType === 'PLATFORM' || accountType === 'TENANT') param.types = ['TENANT']
            if (accountType === 'TENANT') param.types = ['TENANT']
            if (companyId) param.types = ['STORE', 'BUSINESS_SHOPPING'], param.tenantId = companyId
            this.$axios.post(url, param, 'json').then(res => {
               if (res.records) {
                  let companies = res.records
                  companies = companies.map(item => {
                     let leaf
                     if (item.leaf) leaf = true
                     return {value: item.id, label: item.companyName, leaf: leaf}
                  })
                  resolve(companies)
               }
            })
         })
      },
      // 改变单位当前选中
      changeCompany(val){
         if (val.length === 1) return this.publics.companyId = val[0]
         this.publics.companyId = val[1]
      },
      // 搜索角色
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getUser(), this.getOrg()
         this.param.nickName = ''
         this.publics.companyId = ''
         this.companyName = ''
      },
      // 侧边列表当前选中
      handleCurrentChange: (() => {
         let id
         return function (row) {
            if (row.id !== id){
               id = row.id
               this.publics.orgId = row.id
               this.publics.companyId = row.companyId
               this.orgName = row.name
               this.orgIsShow = true
            } else {
               id = ''
               this.publics.orgId = ''
               this.publics.companyId = ''
               this.orgName = ''
               this.orgIsShow = false
               this.$refs.sideTable.setCurrentRow();
            }
            this.page = 1
            this.getUser()
         }
      })(),
      // 主体表格当前选中
      handleTableSelect: (() => {
         let id
         return function (row) {
            if (row.id !== id){
               id = row.id
               this.userId = row.id
            } else {
               id = ''
               this.userId = ''
               this.$refs.mainTable.setCurrentRow();
            }
         }
      })(),
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getUser()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getUser()
      },
      // 获取上传的文件资源
      handleUpload(file){
         let err = beforeUpload(file)
         if (err) this.$message.error(err)
         this.headPortrait = URL.createObjectURL(file.raw);
         this.fileForm.file = file.raw
      },
      // 添加用户
      handleAdd(){
         this.isShow = true
         this.action = 'add'
      },
      // 编辑用户
      handleEdit(row){
         for (let k of Object.keys(this.ruleForm)) k in row && (this.ruleForm[k] = row[k])
         this.headPortrait = row.headPortrait || ''
         this.isShow = true
         this.action = 'edit'
         this.id = row.id
      },
      // 重置表单
      resetForm(){
         for (let k in this.initForm) {
            if (this.action === 'edit' && k === 'userName' && this.isShow) continue
            this.ruleForm[k] = this.initForm[k]
         }
         this.headPortrait = ''
         this.fileForm.file = null
      },
      // 保存用户
      handleSave(){
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            let url = system.AddUser
            let param = new FormData()
            for (let key in this.ruleForm) param.set(key, this.ruleForm[key])
            if (this.fileForm.file) for (let key in this.fileForm) param.set(key, this.fileForm[key])
            if (this.action === 'edit'){
               url = system.editUser
               for (let key in this.publics) param.delete(key)
               param.append('id', this.id)
            }
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: `${this.action === 'add' ? '添加' : '编辑'}成功`,
                     type: 'success'
                  })
                  this.handleClose()
                  this.getUser()
               }
            })
         })
      },
      // 关闭弹窗
      handleClose(){
         this.isShow = false
         this.resetForm()
      },
      // 删除用户
      handleDel(id){
         this.$confirm('确定删除该用户', '删除', {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            let ids = []; ids.unshift(id)
            const param = {ids: ids.toString()}
            const url = system.DelUser
            this.$axios.post(url,param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: '删除成功',
                     type: 'success'
                  })
                  this.getUser()
               }
            })
         })
      },
      // 重置密码
      resetPwd(userName, id){
         this.userId = id
         this.password = ''
         this.userName = userName
         this.isShowPwd = true
      },
      // 保存新密码
      saveNewPwd(){
         const url = system.resetPwd
         const param = { userId: this.userId, password: this.password }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: '修改成功',
                  type: 'success'
               })
               this.isShowPwd = false
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 参与角色
      partakeRole(){
         if (!this.userId) return this.$message({ message: '请选中角色' })
         this.roleIsShow = true
         this.getUnRoleByUser()
         this.getRoleByUser()
      },
      // 参与角色 获取未授予角色列表
      getUnRoleByUser(){
         const url = system.unRoleByUser + `/${this.userId}?limit=10&page=1`
         this.$axios.get(url,{}).then(res => {
            if (res.searchCount) this.unRoleData = res.records
         })
      },
      // 参与角色 获取已授予角色列表
      getRoleByUser(){
         const url = system.roleByUser + `/${this.userId}?limit=10&page=1`
         this.$axios.get(url,{}).then(res => {
            if (res.searchCount) this.roleData = res.records
         })
      },
      // 参与角色 监视未授予角色列表选中变化
      handleSelectionChange(val){
         this.roleIds = val.map(item => (item.id))
      },
      // 参与角色 保存授予角色操作
      saveRelation(){
         // 表单提交验证
         if (this.roleIds.length === 0) {
            this.$alert('选中角色不能为空', '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const param = { roleIds: this.roleIds, userId: this.userId, type: 'USER' }
         const url = system.authRoleUser
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: '角色授权成功',
                  type: 'success'
               })
               this.getUnRoleByUser()
               this.getRoleByUser()
            }
         })
      },
      // 参与角色 角色与用户解绑
      handleUntying(row){
         const delRoleIds = []
         delRoleIds.push(row)
         const param = {
            delRoleIds: delRoleIds,
            userId: this.userId,
            type: 'USER'
         }
         const url = system.authRoleUser
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: '解绑成功',
                  type: 'success'
               })
               this.getUnRoleByUser()
               this.getRoleByUser()
            }
         })
      }
   },
   filters: {
      filterState(val){
         switch (val) {
            case "PASSED":
               return val = '启用'
            case "DISABLED":
               return val = '禁用'
         }
      },
      filterGender(val, that) {
         if (val && that.dictData['gender']) return that.dictData['gender'][val] || val
      },
   }
}
</script>

<style lang="scss">
   .headPortrait{
      width: 35px;
      height: 35px;
      border-radius: 50%;
   }
   .avatar-uploader{
      .avatar-uploader-icon{
         height: 80px!important; width: 80px!important; line-height: 80px!important;
      }
   }
   .user-execute{
      .execute{
         display: flex;
         justify-content: space-between;
         margin-top: 30px;
         padding: 0 2%;
         &>div{
            width: 100%;
         }
         .execute-room{
            border: solid 1px #EBEEF5;
            display: flex;
            flex-direction: column;
         }
         .top-text{
            font-size: 14px;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #EBEEF5;
         }
      }
      .el-icon-d-arrow-right{
         margin-top: 200%;
         font-size: 1.8rem;
         cursor: pointer;
      }
   }
</style>
